<template>
  <div class="container-box">
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      type="border-card"
      class="demo-tabs"
    >
      <el-tab-pane label="省赛" name="1"></el-tab-pane>
      <el-tab-pane label="全国总决赛" name="2"></el-tab-pane>
      <el-tab-pane label="教师赛" name="3"></el-tab-pane>
    </el-tabs>
    <el-table
      :data="tableData"
      stripe
      border
      :default-expand-all="true"
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template #default="props">
          <div class="inputContent">
            <div
              v-for="(item, index) in props.row.ocr_number_select"
              :key="index"
            >
              <div class="outDiv" v-if="index < props.row.num">
                <div class="itemLabel">开票金额:&nbsp;</div>
                <div class="itemContent">
                  <el-input
                    :disabled="disable"
                    @change="changeMoney(props.$index, props.row)"
                    clearable
                    v-model="props.row.ocr_number_select[index]"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="请输入金额"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="收款单位" prop="ocr_company" align="center">
      </el-table-column>
      <el-table-column label="人数" prop="ocr_number" width="60" align="center">
      </el-table-column>
      <el-table-column label="金额" prop="ocr_money" width="90" align="center">
      </el-table-column>
      <el-table-column
        label="发票内容"
        prop="invoice_content"
        width="170"
        align="center"
      >
        <template #default="scope">
          <el-select
            v-model="scope.row.invoice_con"
            :disabled="disable"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in scope.row.invoice_content"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="开票数量" prop="num" width="90" align="center">
        <template #default="scope">
          <el-select
            v-model="scope.row.num"
            :disabled="scope.row.ocr_number_status || disable"
            placeholder="请选择"
            @change="change(scope.$index, scope.row)"
          >
            <el-option
              v-for="item in optionsNum"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="发票备注（此项为发票的备注栏内容）"
        align="center"
      >
        <template #default="scope">
          <div>
            <el-input
              :disabled="disable"
              v-model="scope.row.ocr_memo"
              @change="handleInputReset(scope.row, scope.$index)"
              size="small"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="top-wrap" v-if="tableData.length > 0">
      <div class="formItem">
        <div class="itemWrap">
          <div class="listItem">
            <div
              class="item iTitle icon-label"
              style="border-bottom: 1px solid #ecedf0"
            >
              发票抬头
            </div>
            <div class="item" style="border-bottom: 1px solid #ecedf0">
              <el-input
                v-model="invoInfo.invoice_title"
                placeholder="请输入发票抬头"
                :disabled="disable"
              ></el-input>
            </div>
            <div class="item iTitle icon-label">税号</div>
            <div class="item">
              <el-input
                v-model="invoInfo.duty_paragraph"
                oninput="value=value.replace(/[^A-Za-z0-9]/g,'')"
                placeholder="请输入税号"
                :disabled="disable"
              ></el-input>
            </div>
          </div>

          <div class="listItem">
            <div
              class="item iTitle icon-label"
              style="border-bottom: 1px solid #ecedf0"
            >
              收票邮箱
            </div>
            <div class="itemEamil" style="border-bottom: 1px solid #ecedf0">
              <el-input
                @blur.native.capture="isEmail()"
                v-model.trim="invoInfo.email"
                placeholder="请输入收票邮箱"
                :disabled="disable"
              ></el-input>
            </div>
          </div>
          <div class="btn">
            <div class="promptText"></div>
            <span
              class="step_s"
              v-if="state == '未保存' || state == '已保存'"
              @click="nextStep()"
              >提交</span
            >
            <span class="step_e" v-else>提交</span>
            <div class="promptText">
              <p>注：</p>
              <p>1、因开票数量较多，报名总人数小于30人的只能开具一张发票。</p>
              <p>2、发票一旦提交开具后不退不换，请仔细确认填写的发票信息。</p>
              <p class="hh">
                3、发票按照登记的先后顺序进行开具，预计周期为10-20个工作日，开具完成后会发送至登记的邮箱，请注意查收。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      width="70%"
      top="20px"
      center
      destroy-on-close
      :before-close="handleClose"
    >
      <div class="dialogContent">
        <div class="title">开票结果</div>
        <div class="careful">*请仔细核对发票信息。</div>
        <div
          class="invoiceInform"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <div class="tableContent">
            <div class="company">
              <span>开票公司：</span>
              <span>{{ item.ocr_company }}</span>
            </div>

            <div class="invoice_content">
              <span>发票内容：</span>
              <span>{{ item.invoice_content[item.invoice_con] }}</span>
            </div>

            <div class="invoice_memo_num">
              <div class="invo_memo">
                <span>发票备注：</span>
                <span>{{ item.ocr_memo }}</span>
              </div>
              <div class="invo_num">
                <span>发票张数：</span>
                <span>{{ item.num }}</span>
              </div>
            </div>
            <div class="invoice_money">
              <div v-for="(mon, i) in item.ocr_number_select" :key="i">
                <div class="money" v-if="mon">
                  <span>{{ i + 1 }}&nbsp;发票金额：</span>
                  <span>{{ mon }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="invoFootInfo">
          <div class="info">
            <span>发票抬头：</span>
            <span>{{ invoInfo.invoice_title }}</span>
          </div>
          <div class="info">
            <span>税号：</span>
            <span>{{ invoInfo.duty_paragraph }}</span>
          </div>
          <div class="info">
            <span>收票邮箱：</span>
            <span>{{ invoInfo.email }}</span>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div @click="commit" class="confirm">确认提交</div>
          <div @click="dialogVisible = false" class="backBtn">返回修改</div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios"; // 引入axios
import { invoiceInfo } from "api/apis.js";
import { ElMessage } from "element-plus";
export default {
  name: "invoiceInformation",
  components: {},
  data() {
    return {
      tableData: [],
      optionsNum: [
        {
          value: 1,
          label: 1,
        },
        {
          value: 2,
          label: 2,
        },
        {
          value: 3,
          label: 3,
        },
        {
          value: 4,
          label: 4,
        },
        {
          value: 5,
          label: 5,
        },
      ],
      dialogVisible: false,
      invoice_data: [],
      invoInfo: {
        invoice_title: "", //发票抬头
        memo: "", //发票备注
        email: "", //发票邮箱
        duty_paragraph: "", //税号
      },
      state: "未保存",
      moneyFlag: true,
      inputMoneyFlag: false,
      iFshowEmail: false,
      contentFlag: true,
      disable: null,
      activeName: "1",
      competition_type: "省赛",
      // invoiceList: [
      //   '现代服务*报名费',
      //   '现代服务*技术服务费',
      //   '现代服务*会务费',
      //   '现代服务*会议费'
      // ]
    };
  },
  created() {},
  mounted() {
    this.invoiceData();
  },
  methods: {
    handleClick(name) {
      this.tableData = [];
      if (name.props.name == "1") {
        this.competition_type = "省赛";
      } else if (name.props.name == "2") {
        this.competition_type = "国赛";
      } else if (name.props.name == "3") {
        this.competition_type = "教师赛";
      }
      this.invoiceData();
    },
    nextStep() {
      this.contentFlag = true;
      this.moneyFlag = true;
      let dataobj = new FormData();
      this.tableData.forEach((ele) => {
        let moneyNum = 0;
        if (ele.ocr_number_select.length > 1) {
          ele.ocr_number_select = ele.ocr_number_select.filter(
            (element) => element !== ""
          );
        }
        if (
          ele.invoice_con != null &&
          ele.invoice_con != undefined &&
          ele.invoice_con !== ""
        ) {
          this.contentFlag = true;
        } else {
          this.contentFlag = false;
        }
        ele.num = ele.ocr_number_select.length;
        if (ele.ocr_number_select.length > 0) {
          ele.ocr_number_select.forEach((item) => {
            if (item) {
              moneyNum += Number(item);
              this.inputMoneyFlag = true;
            } else {
              this.inputMoneyFlag = false;
            }
          });
          if (moneyNum > ele.ocr_money) {
            this.moneyFlag = false;
          }
        }
      });
      if (!this.moneyFlag) {
        ElMessage({
          message: "当前开票金额超过总金额，请重新输入！",
          type: "error",
        });
        return;
      }
      if (!this.inputMoneyFlag) {
        ElMessage({
          message: "请输入开票金额！",
          type: "error",
        });
        return;
      }
      dataobj.append(
        "user_id",
        JSON.parse(localStorage.getItem("UPuser")).user_id
      );
      dataobj.append("invoice_type", "普票");
      dataobj.append("invoice_title", this.invoInfo.invoice_title);
      dataobj.append("memo", this.invoInfo.memo);
      dataobj.append("email", this.invoInfo.email);
      dataobj.append("duty_paragraph", this.invoInfo.duty_paragraph);
      dataobj.append("company_info", JSON.stringify(this.tableData));
      dataobj.append("competition_type", this.competition_type);
      if (
        this.invoInfo.duty_paragraph &&
        this.invoInfo.email &&
        this.invoInfo.invoice_title
      ) {
        this.isEmail();
        if (this.iFshowEmail) {
          invoiceInfo.invoiceSave(dataobj).then((res) => {
            if (res.code == 200) {
              this.dialogVisible = true;
              this.invoiceData();
            }
          });
        }
      } else {
        ElMessage({
          message: "请完善数据!",
          type: "error",
        });
      }
    },
    //获取开票数据
    invoiceData() {
      let dataobj = new FormData();
      dataobj.append(
        "user_id",
        JSON.parse(localStorage.getItem("UPuser")).user_id
      );
      dataobj.append("competition_type", this.competition_type);
      invoiceInfo.invoiceData(dataobj).then((res) => {
        if (res.code == "200") {
          let dataobj = JSON.parse(res.data);
          console.log(dataobj);
          if (dataobj) {
            const { payment_merge_data, invoice_data } = dataobj;
            this.tableData = payment_merge_data || [];
            this.invoice_data = invoice_data || [];
            this.tableData.forEach((tableItem) => {
              if (!tableItem.ocr_number_select) {
                tableItem.ocr_number_select = ["", "", "", "", ""];
              }
              if (!tableItem.num) {
                tableItem.num = 1;
                tableItem.ocr_number_select = [""];
              }
              if (
                !tableItem.invoice_con &&
                tableItem.invoice_content.length > 0
              ) {
                tableItem.invoice_con = 0;
              }
              tableItem.ocr_number_status =
                tableItem.ocr_number >= 30 ? false : true;
              //发票内容、开票数量、下拉输入框从company_info中获取，其他的取payment_merge_data
              if (this.invoice_data.length > 0) {
                this.invoInfo.invoice_title =
                  this.invoice_data[0].invoice_title;
                this.invoInfo.memo = this.invoice_data[0].memo;
                this.invoInfo.email = this.invoice_data[0].email;
                this.invoInfo.duty_paragraph =
                  this.invoice_data[0].duty_paragraph;
                this.state = this.invoice_data[0].state;
                this.disable =
                  this.state != "未保存" && this.state != "已保存"
                    ? true
                    : false;
                if (
                  this.invoice_data[0].company_info &&
                  this.invoice_data[0].company_info.length > 0
                ) {
                  this.invoice_data[0].company_info.forEach((item) => {
                    if (item.ocr_company == tableItem.ocr_company) {
                      tableItem.invoice_con = item.invoice_con;
                      if (item.ocr_number_select.length > 0) {
                        tableItem.ocr_number_select = item.ocr_number_select;
                      } else {
                        tableItem.ocr_number_select = [""];
                      }
                      if (item.num) {
                        tableItem.num = item.num;
                      } else {
                        if (tableItem.ocr_number_status) {
                          tableItem.num = 1;
                        }
                      }
                      tableItem.ocr_memo = item.ocr_memo;
                    }
                  });
                }
              }
              console.log(this.tableData);
            });
          }
        } else {
          ElMessage({
            message: "获取数据失败！",
            type: "error",
          });
        }
      });
      this.$forceUpdate();
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    commit() {
      let dataobj = new FormData();
      dataobj.append(
        "user_id",
        JSON.parse(localStorage.getItem("UPuser")).user_id
      );
      dataobj.append("competition_type", this.competition_type);
      invoiceInfo.invoiceConfirm(dataobj).then((res) => {
        if (res.code == "200") {
          this.invoiceData();
          ElMessage({
            message: res.msg,
            type: "success",
          });
        }
      });
      this.dialogVisible = false;
    },
    changeMoney(index, row) {},
    change(index, row) {
      this.tableData[index].ocr_number_select = ["", "", "", "", ""];
    },
    handleInputReset(row, index) {
      if (row) {
        this.tableData[index].ocr_memo = row.ocr_memo;
      }
    },
    // 邮箱验证
    isEmail() {
      let myreg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (this.invoInfo.email == "") {
        this.$message.error("请输入邮箱");
      } else if (!myreg.test(this.invoInfo.email)) {
        this.$message.error("邮箱格式不正确");
        this.iFshowEmail = false;
        return;
      } else {
        this.iFshowEmail = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
